import React, { FC, useEffect } from 'react';
import { WidgetProps } from '@wix/yoshi-flow-editor';
import { Button } from 'wix-ui-tpa';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  productId: string;
  sku: string;
};

const Widget: FC<WidgetProps<ControllerProps>> = ({ productId, sku }) => {
  // Function to provide default SKU if none is passed in props
  const getSku = () => sku || '65U8K';

  useEffect(() => {
    // Retry mechanism to bind PriceSpider widget
    let attempts = 0;
    const maxAttempts = 10; // Retry up to 10 times
    const interval = setInterval(() => {
      if (window.PriceSpider?.WidgetBinder) {
        window.PriceSpider.WidgetBinder.bind();
        console.log('PriceSpider widget bind called');
        clearInterval(interval); // Stop checking once it binds
      } else if (attempts >= maxAttempts) {
        clearInterval(interval); // Stop after max attempts
        console.warn('PriceSpider.WidgetBinder not found after 10 seconds.');
      }
      attempts += 1;
    }, 1000); // Retry every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, [sku]); // Depend on SKU so it re-runs if SKU changes

  return (
    <div className={st(classes.root, {})} data-hook="ProductCtas-wrapper">
      <div className={classes.psContainer}>
        {/* PriceSpider widget div */}
        <div className="ps-widget" ps-sku={getSku()}></div>
      </div>
    </div>
  );
};

export default Widget;
